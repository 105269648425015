import React from "react"
import SeoHead from "../../components/global/seoHead"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"
import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"

import "../../sass/pages/case-studies/cs-global.scss"
import "../../sass/pages/branding/square-projects.scss"

import brandingFeaturedImage from "../../../static/branding/square-projects/square-projects-featured-image.png"
import SquareMain from "../../components/branding/square-projects/squareMain"
import SquareFullwidth from "../../components/branding/square-projects/squareFullWidth"
import SquareMoreBranding from "../../components/branding/square-projects/squareMoreBranding"


export const Head = () => (
    <>
        <SeoHead 
            ogImage={brandingFeaturedImage}
            title="Square Projects: Architecting a Brand | WowMakers"
            description="Discover how we built a solid brand foundation for Square Projects. Our design precision matches their architectural expertise."
        />
    </>
)

const SquareProjectsEventsBranding = () => {
    
    return (
        <Layout>
            <Seo
                bid="branding-square"
                bclass="case-studies-page branding"
            ></Seo>
            <ScrollIndicationHeightZero />
            <SquareMain />
            <SquareFullwidth />
            <SquareMoreBranding />
        </Layout>
    )
}

export default SquareProjectsEventsBranding